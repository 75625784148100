<template>
    <b-container class="p-4">
        <b-row>
            <b-col md="8" class="my-0">
                <b-input-group size="sm" prepend="Search" class="mt-1">
                    <b-form-input
                        v-model="searchTitle"
                        placeholder="Enter the game title..."
                    ></b-form-input>
                    <b-input-group-append>
                        <b-button
                            :disabled="!searchTitle"
                            variant="primary"
                            @click="loadTeams"
                            >OK</b-button
                        >
                        <b-button
                            :disabled="!searchTitle"
                            variant="outline-primary"
                            @click="clearSearch"
                            >Clear</b-button
                        >
                    </b-input-group-append>
                </b-input-group>
            </b-col>
            <b-col>
                <div class="float-right">
                    <b-pagination
                        v-model="currentPage"
                        @change="onPageChange"
                        :total-rows="totalRows"
                        :per-page="0"
                        class="my-0"
                        size="sm"
                    ></b-pagination>
                </div>
            </b-col>
        </b-row>

        <b-table
            class="mt-3 w-auto table table-sm text-xsmall font-weight-light games-table"
            :items="teamTableData"
            :fields="teamTableFields"
            :busy="loading"
            ref="teamTable"
            current-page="currentPage"
            hover
            small
            select-mode="single"
            sticky-header="200px"
            head-variant="light"
            responsive="sm"
            selectable
            show-empty
            @row-selected="onTeamRowSelect"
        >
            <template #empty>
                <div class="text-center text-muted my-2">
                    There are no records to show
                </div>
            </template>

            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    Loading...
                </div>
            </template>

            <template #cell(status)="data">
                <b-badge :variant="getStatusBadge(data.value)">{{
                    data.value
                }}</b-badge>
            </template>

            <template #cell(progress)="data">
                <div class="mt-1">
                    <b-progress
                        :value="data.value.day"
                        :max="data.value.total_days - 1"
                        :variant="getStatusBadge(data.item.status)"
                    >
                    </b-progress>
                </div>
            </template>
        </b-table>
    </b-container>
</template>
<script>
import lodash from 'lodash'
import { formatDateString, gameStatusBadgeMap, GameStatus } from '@/views'
import ApiService from '@/services/api.service'

export default {
    data() {
        return {
            currentPage: 1,
            totalRows: 0,
            searchTitle: null,
            perPage: 8,
            loading: false,
            teams: null,
            teamTableData: [],
            teamTableFields: [
                'title',
                'team',
                'start',
                'progress',
                'finish',
                'status',
            ],
        }
    },
    methods: {
        onTeamRowSelect(items) {
            let team = items[0]

            if (!team) {
                return
            }

            if (team.status == GameStatus.INITIALIZED) {
                this.$bvToast.toast('The game has not been started yet...', {
                    title: 'Information',
                    toaster: 'b-toaster-top-center',
                    variant: 'success',
                    solid: true,
                })

                this.$refs.teamTable.clearSelected()

                return
            }

            this.$router
                .push({ name: 'gamePlay', params: { teamId: team.id } })
                .catch((err) => {})
        },
        getStatusBadge(status) {
            return gameStatusBadgeMap[status]
        },
        clearSearch() {
            this.searchTitle = null
            this.loadTeams()
        },
        onPageChange(page) {
            this.currentPage = page
            this.loadTeams()
        },
        loadTeams() {
            this.loading = true
            this.teams = null
            this.teamTableData = []

            ApiService.getTeam({
                title: this.searchTitle,
                offset: (this.currentPage - 1) * this.perPage,
                limit: this.perPage,
            }).then(
                (response) => {
                    this.teams = response.data.data
                    this.totalRows = parseInt(
                        lodash.get(
                            response.headers,
                            'x-total-count',
                            this.perPage
                        )
                    )

                    this.teams.forEach((team) => {
                        this.teamTableData.push({
                            id: team.id,
                            title: team.game.title,
                            team: team.name,
                            start: !!team.game.start_at
                                ? formatDateString(team.game.start_at)
                                : '',
                            finish: !!team.game.stopped_at
                                ? formatDateString(team.game.stopped_at)
                                : '',
                            progress: team.game.progress,
                            status: team.game.status,
                        })
                    })

                    this.loading = false
                },
                (error) => {
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        variant: 'danger',
                        solid: true,
                    })
                    this.loading = false
                }
            )
        },
    },
    mounted() {
        this.loadTeams()
    },
}
</script>
<style>
.games-table {
    min-height: 400px;
}
</style>
